.policy {
  h1 {
    margin: 20px 0px 10px;
    font-size: 40px;
    font-weight: 800;
  }

  h2 {
    margin: 16px 0px 8px;
    font-size: 36px;
    font-weight: 800;
  }

  h3 {
    margin: 8px 0px 4px;
    font-size: 24px;
    font-weight: 800;
  }

  h4 {
    margin: 8px 0px;
    font-size: 20px;
    font-weight: 800;
  }

  a:link {
    color: #222;
  }

  a:visited {
    color: #000;
  }

  a:focus {
    color: #777;
  }

  a:hover {
    color: #777;
  }

  a:active {
    color: #222;
  }

  ol,
  ul {
    margin-left: 20px;
  }

  padding: 24px 12px;
}
