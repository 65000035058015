@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

html,
body,
#root,
.wrapper,
.main,
.carousel,
.slider-wrapper,
.slider,
.slide,
.image {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Montserrat", sans-serif;
  line-height: 1.45;
  background: #f1f1f1;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  /* max-width: 500px;
  max-height: 1000px; */

  /* display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 24px; */

  position: relative;

  box-shadow: 0px 3px 10px 0px #0005;
}

.back {
  border: none;
  background-color: transparent;
  color: #fff;
  -webkit-filter: drop-shadow(1px 1px 2px #00000052);
  filter: drop-shadow(1px 1px 2px #00000052);
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 16px;
  font-size: 24px;
}

.onlylike {
  position: fixed;
  width: 100%;
  top: 16px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 2px #00000099;
  letter-spacing: 1px;
  line-height: 1;

  span {
    margin-left: 1.5px;
    letter-spacing: 1.5px;
    font-weight: 700;
    color: #00a5df;
  }
}

.carousel,
.image {
  object-fit: cover;
}

.carousel {
  position: fixed !important;
  top: 0;
  left: 0;
}

.pin-wrapper {
  padding: 8px 4px;
  display: inline-block;
}

.pin {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #fff9;
  box-shadow: 0px 4px 8px 0px #00000052;
  transition: width 0.3s ease-in-out, background 0.3s ease-in-out;
  cursor: pointer;
}

.pin-selected {
  width: 28px;
  background: #fffc;
  cursor: auto;
}

.controls {
  position: fixed;
  bottom: 46.5px;
  width: 100%;

  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 10vw;
}

.dismiss,
.like,
.next,
.message {
  width: min(20vw, 180px);
  height: min(20vw, 180px);
  border-radius: 50%;
  font-size: min(10vw, 90px);
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px 0px #00000052;
  background-color: #fffc;
}

.scroll {
  z-index: 1;
  width: auto;
  /* height: 100vh; */
  position: absolute !important;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 24px;
  overflow: auto;

  background-color: #f1f1f1;
  padding: 24px 24px 16px 24px;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}

.placeholder {
  pointer-events: all;
  height: 100vh;
  flex-shrink: 0;
}

.title {
  display: block;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 8px;
}

.text {
  display: block;
  font-size: 16px;
  font-weight: 400;
}

.buttons {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  align-self: center;
  gap: 10vw;
}

.dismiss {
  color: #d00000;
}

.like {
  color: #02aff0;
}

.next {
  color: #d00000;
  box-shadow: 0px 4px 8px 0px #00000026;
}

.message {
  color: #02aff0;
  box-shadow: 0px 4px 8px 0px #00000026;

  svg {
    margin-bottom: -6px;
  }
}

.footer {
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  text-align: center;

  gap: 8px;

  color: #808080;
  font-weight: 700;

  a,
  a:link {
    font-weight: 400;
    color: #222;
  }

  a:hover,
  a:active {
    color: #555;
  }
}

.move-enter {
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;

  transform: translateX(100%);
}

.move-enter.move-enter-active {
  transform: translateX(0%);
  transition: transform 500ms ease-in;
}
